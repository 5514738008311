import { createContext, useContext } from 'react';

interface ITAccordionContext {
  activeItem: string;
  // eslint-disable-next-line no-unused-vars
  setActiveItem: (index: string) => void;
}

export const AccordionContext = createContext<ITAccordionContext | null>(null);

export default function useAccordionContext(): ITAccordionContext {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error('AccordionContext used outside Accordion Component');
  }
  return context;
}
