import { memo, ReactNode } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import Footer from '../Footer';

interface InternalLayoutProps {
  children: ReactNode;
  path?: string;
}

const Container = styled.main`
  background: ${props => props.theme.colors.mainBackground};
  transition: background-color 0.5s ease 0s;
`;

function InternalLayout({ children, path = '' }: InternalLayoutProps) {
  return (
    <>
      <Header activeLink={path} internal />
      <Container>{children}</Container>
      <Footer activeLink={path} />
    </>
  );
}

export default memo(InternalLayout);
