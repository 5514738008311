import { _t } from '../../utils/messages';

// eslint-disable-next-line import/prefer-default-export
export const messages = {
  mainSection: {
    title: () => _t('mainSection.title', 'vignette auto'),
    description: () =>
      _t(
        'mainSection.description',
        'PAYEZ VOTRE VIGNETTE ET ROULEZ EN TOUTE SÉRÉNITE',
      ),
  },
  howItWorksSection: {
    title: () => _t('howItWorksSection.title', 'comment ça marche ?'),
    subTitle: () => _t('howItWorksSection.subTitle', 'paiement de vignette'),
    infoCard: {
      title: () =>
        _t('howItWorksSection.infoCard.title', 'SAISISSEZ VOS INFORMATIONS'),
      subTitle: () =>
        _t(
          'howItWorksSection.infoCard.subTitle',
          'Saisissez vos données personnelles et celles de votre véhicule.',
        ),
    },
    paymentCard: {
      title: () =>
        _t('howItWorksSection.paymentCard.title', 'PAYEZ PAR CARTE BANCAIRE'),
      subTitle: () =>
        _t(
          'howItWorksSection.paymentCard.subTitle',
          'Renseignez les données de votre carte bancaire et validez le paiement avec le code 3DS reçu par SMS.',
        ),
    },
    downloadReceiptCard: {
      title: () =>
        _t(
          'howItWorksSection.downloadReceiptCard.title',
          'REÇU ENVOYÉ PAR EMAIL',
        ),
      subTitle: () =>
        _t(
          'howItWorksSection.downloadReceiptCard.subTitle',
          "Il sera envoyé sur l'email que vous aurez renseigné.",
        ),
    },
  },
  raffleSection: {
    title: () => _t('raffleSection.title', 'tombola'),
    subTitle: () => _t('raffleSection.subTitle', 'participer à la'),
    description: () =>
      _t(
        'raffleSection.description',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, tempor incididunt ut labore',
      ),
    actionBtnTitle: () => _t('raffleSection.actionBtnTitle', 'Découvrir'),
  },
  faqSection: {
    title: () => _t('faqSection.title', 'foire aux questions'),
    subTitle: () => _t('faqSection.subTitle', "Besoin d'aide"),
    actionLink: () => _t('faqSection.actionLink', 'voir toutes les questions'),
    questions: {},
  },
  contactSection: {
    title: () => _t('contactSection.title', 'contactez-nous'),
    subTitle: () => _t('contactSection.subTitle', "besoin d'informations"),
    description: () =>
      _t(
        'contactSection.description',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, tempor incididunt ut labore',
      ),
    cancelText: () => _t('faqSection.cancelText', 'annuler'),
    form: {
      name: () => _t('contactSection.form.name', 'nom'),
      email: () => _t('contactSection.form.email', 'email'),
      subject: () => _t('contactSection.form.subject', 'sujet'),
      content: () => _t('contactSection.form.content', 'message'),
    },
  },
};
