import { memo, useMemo, useRef } from 'react';
import useAccordionContext from './useAccordionContext';

interface AccordionItemProps {
  title?: string;
  description?: string;
  index: string;
}

function AccordionItem({ title, description, index }: AccordionItemProps) {
  const { activeItem, setActiveItem } = useAccordionContext();

  const active = useMemo(() => activeItem === index, [activeItem, index]);

  const contentEl = useRef<HTMLDivElement>(null);

  return (
    <div className="accordion_item">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        tabIndex={Number(index)}
        className={`accordion_item_head ${active}`}
        onClick={() => (active ? setActiveItem('') : setActiveItem(index))}
      >
        <h3 className={`accordion_item_title ${active}`}>{title}</h3>
        <span className={`accordion_item_icon ${active}`}>
          {active ? (
            <svg
              version="1.2"
              baseProfile="tiny-ps"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19 19"
              width="19"
              height="19"
            >
              <path d="M 0 9 L 19 9 L 19 10 L 0 10" />
            </svg>
          ) : (
            <svg
              version="1.2"
              baseProfile="tiny-ps"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19 19"
              width="19"
              height="19"
            >
              <path d="M0 9L19 9L19 10L0 10L0 9ZM10 0L10 19L9 19L9 0L10 0Z" />
            </svg>
          )}
        </span>
      </div>
      <div
        ref={contentEl}
        className={`description_wrapper ${active}`}
        style={
          active
            ? { height: contentEl.current?.scrollHeight }
            : { height: '0px' }
        }
      >
        {description && (
          <div
            className={`description ${active}`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  );
}

export default memo(AccordionItem);
