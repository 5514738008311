import { memo, ReactNode } from 'react';
import styled from 'styled-components';

const StyledInternalBody = styled.div`
  padding: 50px;
  flex-grow: 1;
  @media only screen and (max-width: 1024px) {
    padding: 30px 40px;
  }
  @media only screen and (max-width: 640px) {
    padding: 30px;
  }
`;

interface InternalBodyProps {
  children: ReactNode;
}

function InternalBody({ children }: InternalBodyProps) {
  return <StyledInternalBody>{children}</StyledInternalBody>;
}

export default memo(InternalBody);
