import { memo, ReactNode } from 'react';
import styled from 'styled-components';
// import ToggleSwitch from '../ToggleSwitch';
// import useThemeSwitch from '../../hooks/useThemeSwitch';

const StyledInternalBanner = styled.div<{
  BannerBg?: string;
  BgPosition?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px 50px 50px;
  min-height: 330px;
  background: ${({ BannerBg }) => (BannerBg ? `url(${BannerBg})` : 'none')}
    no-repeat ${({ BgPosition }) => BgPosition || '50% 50%'} transparent;
  background-size: cover;
  @media only screen and (max-width: 1024px) {
    min-height: auto;
    padding: 30px 40px;
  }
  @media only screen and (max-width: 640px) {
    padding: 30px;
  }
  .header-wrapper {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 50px;
      max-width: 30%;
    }

    @media only screen and (max-width: 640px) {
      max-width: 50%;
    }
  }
`;
interface InternalBannerProps {
  BannerBg?: string;
  BgPosition?: string;
  children: ReactNode;
}
function InternalBanner({
  BannerBg,
  BgPosition,
  children,
}: InternalBannerProps) {
  // const { themeMode, toggleTheme } = useThemeSwitch();

  return (
    <StyledInternalBanner BannerBg={BannerBg} BgPosition={BgPosition}>
      {children}
    </StyledInternalBanner>
  );
}

export default memo(InternalBanner);
