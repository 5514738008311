import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { AccordionContext } from './useAccordionContext';
import AccordionItem from './AccordionItem';

interface AccordionProps {
  children: ReactElement[];
  fallWidth?: boolean;
}

const StyledAccordion = styled.div<{ fallWidth?: boolean }>`
  max-width: ${({ fallWidth }) => (fallWidth ? '100%' : '90%')};
  width: ${({ fallWidth }) => (fallWidth ? '100%' : '970px')};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 40px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    max-width: none;
  }
  .accordion_item {
    margin-bottom: 20px;
    transition: all 0.2s ease;
    transform: scale(1);
    @media only screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }
    &:hover {
      transform: scale(1.02);
      @media only screen and (max-width: 1024px) {
        transform: scale(1.01);
      }
    }
    .accordion_item_head {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 35px;
      padding: ${props =>
        props.theme.rtl ? '24px 45px 25px 0' : '24px 0 25px 45px'};
      box-shadow: 0 0 20px 0px ${props => props.theme.colors.shadow};
      cursor: pointer;
      z-index: 2;
      position: relative;
      background-color: ${props => props.theme.colors.mainBackground};
      transition: border-radius 0.2s ease;
      @media only screen and (max-width: 1024px) {
        padding: ${props =>
          props.theme.rtl ? '15px 20px 15px 10px' : '15px 10px 15px 20px'};
      }

      &.true {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .accordion_item_title {
        font-family: ${props => props.theme.fonts.SourceSansPro};
        font-size: 18px;
        flex-grow: 1;
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
        }
      }
      .accordion_item_icon {
        width: 40px;
        @media only screen and (max-width: 1024px) {
          width: 34px;
        }
        @media only screen and (max-width: 640px) {
          width: 24px;
        }
        svg {
          transform: rotate(0);
          transition: all 0.2s ease;
          path {
            fill: ${props => props.theme.colors.primary};
          }
        }
        &.true {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  div.description_wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
    z-index: 1;
    position: relative;
    background: ${props => props.theme.colors.accordionBackground};
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
    box-shadow: 0 0 20px 0px ${props => props.theme.colors.shadow};
    @media only screen and (max-width: 1024px) {
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    .description {
      padding: ${props =>
        props.theme.rtl ? '20px 45px 20px 20px' : '20px 20px 20px 45px'};
      font-size: 15px;
      line-height: 20px;
      @media only screen and (max-width: 1024px) {
        padding: 20px;
        font-size: 14px;
      }
      p {
        margin-bottom: 10px;
      }
      ul,
      ol {
        margin-bottom: 10px;
        padding-left: 20px;
      }
      ol {
        li {
          list-style: decimal;
        }
      }
      ul {
        li {
          list-style: circle;
        }
      }
      a {
        color: ${props => props.theme.colors.primary};
      }
      table {
        width: 100%;
        border: 1px solid #dee2e6;
        border-collapse: collapse;
        margin-bottom: 20px;
        margin-top: 20px;
        background-color: ${props => props.theme.colors.mainBackground};
        th,
        td {
          border: 1px solid #ddd;
          padding: 8px;
          height: 30px;
          text-align: left;
        }
        th {
          font-weight: ${props => props.theme.fontWeights.SourceSansPro.bold};
        }
      }
    }
  }
`;

function Accordion({ children, fallWidth }: AccordionProps) {
  const [activeItem, setActiveItem] = useState<string>('');

  return (
    <AccordionContext.Provider value={{ activeItem, setActiveItem }}>
      <StyledAccordion fallWidth={fallWidth}>{children}</StyledAccordion>
    </AccordionContext.Provider>
  );
}

Accordion.AccordionItem = AccordionItem;
export default Accordion;
