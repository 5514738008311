import { memo } from 'react';
// import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PageProps } from 'gatsby';
import InternalBanner from '../components/InternalBanner';
import InternalBody from '../components/InternalBody';
import SEO from '../components/SEO';
import SectionHeading from '../components/SectionHeading';
import VignetteImg from '../images/FAQ-bann.jpg';
import Accordion from '../components/Accordion';
import { FAQS } from '../utils/data';

import { messages as sectionMessages } from '../components/Sections/messages';
import InternalLayout from '../components/Layout/InternalLayout';

const FaqPage = ({ location }: PageProps) => {
  const { t } = useTranslation();

  console.log({ location });

  return (
    <>
      <SEO
        // title={`${data.site.siteMetadata.title} | vignette.sgmaroc.com`}
        keywords={[
          'paiement vignette maroc',
          'ma vignette',
          'payer en ligne vignette maroc',
          'paiement vignette maroc en ligne',
          'payer vignette en ligne',
          'vignette en ligne',
          'ma vignette maroc',
          'paiement en ligne maroc',
          'paiement internet maroc',
          'impot voiture maroc',
          'taxe voiture maroc',
          'payer taxe voiture maroc',
          'payer dariba maroc',
          'payer dariba en ligne',
          'payer dariba',
          'vignette 2022',
          'payer vignette 2022',
          'payer vignette 2022 maroc',
        ]}
      />
      <InternalLayout path={location.pathname.replace(/\//g, '')}>
        <InternalBanner BannerBg={VignetteImg} BgPosition="50% 100%">
          <SectionHeading
            title={t(sectionMessages.faqSection.title())}
            subTitle="&nbsp;"
            align="center"
            withShadow
          />
        </InternalBanner>

        <InternalBody>
          <Accordion fallWidth>
            {FAQS.map(({ title, description }, index) => (
              <Accordion.AccordionItem
                description={description}
                title={title}
                index={index.toString()}
                key={index.toString()}
              />
            ))}
          </Accordion>
        </InternalBody>
      </InternalLayout>
    </>
  );
};

export default memo(FaqPage);
